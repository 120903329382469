import { useQuery, useMutation } from '@apollo/client';
import * as jwt from 'jsonwebtoken';
import { Helmet } from 'react-helmet';
import { useParams, useHistory } from 'react-router-dom';
import { Button, Header, Image } from 'semantic-ui-react';
import CompassLogo from '../../assets/images/compass/logo_dark_green.svg';
import { ErrorSegment, LoadingSegment } from '../../components';
import { SET_FAVORITE } from '../../graphql/mutations';
import { GetMetabaseDashboards } from '../../graphql/queries';

interface State {
  dashboard_id: string | null;
}

export const Metabase = () => {
  const history = useHistory();
  const { dashboard_id } = useParams<State>();
  const { loading, error, data } = useQuery(GetMetabaseDashboards, {
    variables: {
      dashboard_id,
    },
    fetchPolicy: 'cache-and-network',
  });
  const [setFavorite] = useMutation(SET_FAVORITE, {
    refetchQueries: [
      {
        query: GetMetabaseDashboards,
        variables: {
          dashboard_id,
        },
      },
    ],
  });

  const renderMetabase = () => {
    if (loading) {
      return <LoadingSegment />;
    } else if (error) {
      return <ErrorSegment message={JSON.stringify(error)} />;
    }
    if (process.env.REACT_APP_METABASE_SECRET_KEY && process.env.REACT_APP_METABASE_SITE_URL && data) {
      const payload = {
        resource: {
          dashboard: data.compass_metabase_dashboard_by_pk.metabase_id,
        },
        params: {},
        exp: Math.round(Date.now() / 1000) + 10 * 60, // 10 minute expiration
      };
      const token = jwt.sign(payload, process.env.REACT_APP_METABASE_SECRET_KEY);
      const iframeUrl =
        process.env.REACT_APP_METABASE_SITE_URL + '/embed/dashboard/' + token + '#bordered=false&titled=true';
      return <iframe title="Metabase" src={iframeUrl} frameBorder="0" width="100%" height="90%"></iframe>;
    }
  };
  return (
    <>
      <Helmet>
        <title>Compass</title>
      </Helmet>
      <div className="compass">
        <div className="header-strip" style={{ padding: '15px', display: 'flex' }}>
          <Button basic content="Back" icon="arrow left" onClick={() => history.goBack()} />
          <div style={{ margin: '0 auto', display: 'flex' }}>
            <Image src={CompassLogo} style={{ height: '50px' }} />
            <Header
              as="h1"
              style={{
                lineHeight: '0px',
                fontSize: ' 28px',
                marginLeft: '15px',
              }}
            >
              Compass
            </Header>
          </div>
          {data && data.compass_metabase_dashboard_by_pk ? (
            <>
              <Button
                basic
                toggle
                active={data.compass_metabase_dashboard_by_pk.favorite}
                icon="star"
                onClick={() => {
                  setFavorite({
                    variables: {
                      favorite: !data.compass_metabase_dashboard_by_pk.favorite,
                      dashboard_id,
                    },
                  });
                }}
              />
              <Button
                basic
                content="Share"
                icon="share"
                onClick={() => {
                  navigator.clipboard.writeText(window.location.href);
                }}
              />
            </>
          ) : null}
        </div>
      </div>
      {renderMetabase()}
    </>
  );
};
