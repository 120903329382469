import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Form, Grid, Header, Image, Message, Segment } from 'semantic-ui-react';
import NavigatorLogo from '../../assets/images/wheelhouse_navigator_logo.svg';
import { Credentials, Error } from '../../types';
import ForgotPasswordForm from './ForgotPasswordForm';
import PasswordResetForm from './PasswordResetForm';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Login = () => {
  const query = useQuery();
  const history = useHistory();
  const [showFull, setShowFull] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [needsReset, setNeedsReset] = useState(null);
  const [showCode, setShowCode] = useState(false);
  const [authError, setAuthError] = useState<Error>({
    hasError: false,
    message: '',
  });

  const [userCredentials, setUserCredentials] = useState<Credentials>({
    email: '',
    password: '',
  });

  const loginUser = () => {
    //TODO: Add client validation
    setLoading(true);
    Auth.signIn(userCredentials.email, userCredentials.password)
      .then((cognito_user) => {
        setLoading(false);
        switch (cognito_user.challengeName) {
          case 'NEW_PASSWORD_REQUIRED':
            setUser(cognito_user);
            setNeedsReset(true);
            break;
          default:
            history.push({ pathname: '/dashboard', state: { user } });
            break;
        }
      })
      .catch((err) => {
        setLoading(false);
        setAuthError({
          hasError: true,
          message: err.message,
        });
      });
  };

  if (query.get('reset') === 'true' && !needsReset) {
    setNeedsReset(true);
    setShowCode(true);
  }

  return (
    <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" textAlign="center">
          <Image src={NavigatorLogo} style={{ width: '80%' }} />
        </Header>
        {authError.hasError && <Message negative>{authError.message}</Message>}
        {showFull && !needsReset && <ForgotPasswordForm />}
        {needsReset && !showFull && <PasswordResetForm user={user} show_code={showCode} />}
        {/* Primary form is not imported to make the primary hook lifecycle easier */}
        {!needsReset && !showFull && (
          <div>
            <Form size="large" onSubmit={loginUser}>
              <Segment stacked>
                <Form.Input
                  fluid
                  icon="user"
                  iconPosition="left"
                  placeholder="E-mail address"
                  value={userCredentials.email}
                  type="email"
                  onChange={(e, { value }) => setUserCredentials({ ...userCredentials, email: value })}
                />
                <Form.Input
                  fluid
                  icon="lock"
                  iconPosition="left"
                  value={userCredentials.password}
                  onChange={(e, { value }) => setUserCredentials({ ...userCredentials, password: value })}
                  placeholder="Password"
                  type="password"
                />
                <Button color="teal" fluid size="large" loading={loading}>
                  Login
                </Button>
              </Segment>
            </Form>
            <Message>
              <a href="mailto:aditya@wheelhousedmg.com?subject=I would like access to Navigator!">Request New Access</a>{' '}
              or{' '}
              <a
                href="#reset"
                onClick={() => {
                  setShowFull(true);
                }}
              >
                Reset My Password
              </a>
            </Message>
          </div>
        )}
      </Grid.Column>
    </Grid>
  );
};

export default Login;
