import { useState, useEffect } from 'react';
import { Switch, Route, useHistory, Redirect } from 'react-router-dom';
import { Menu, Form } from 'semantic-ui-react';
import styled from 'styled-components';
import { ClientManager } from '../';
import ProfileSettings from './ProfileSettings';

export const StyledMenu = styled(Menu)`
  &.ui.pointing.secondary.menu {
    padding-left: 60px;
    box-shadow: 0px 3px 6px -2px rgba(0, 0, 0, 0.16);
    border: 0;
    .item {
      margin: 0 20px;
      font-family: Lato;
      font-weight: normal;
      font-size: 14px;
      color: #939498;
      &:hover {
        color: #3dbeaf;
        cursor: pointer;
      }
      &.active {
        font-weight: 800;
        color: #3dbeaf;
        border-color: #3dbeaf;
        &:hover {
          color: #3dbeaf;
          border-color: #3dbeaf;
        }
      }
    }
  }
  margin-bottom: 50px !important;
`;

export const Settings = () => {
  const history = useHistory();
  const [selectedRouter, setSelectedRouter] = useState('user');

  useEffect(() => {
    const { pathname } = history.location;
    if (pathname === '/settings/user') {
      setSelectedRouter('user');
    } else if (pathname === '/settings/organization') {
      setSelectedRouter('organization');
    } else {
      setSelectedRouter('client-manager');
    }
  }, [history.location]);

  return (
    <>
      <StyledMenu pointing secondary>
        <Menu.Item
          name="User"
          active={selectedRouter === 'user'}
          onClick={() => {
            setSelectedRouter('user');
            history.push('/settings/user');
          }}
        />
        <Menu.Item
          name="Organization"
          active={selectedRouter === 'organization'}
          onClick={() => {
            setSelectedRouter('organization');
            history.push('/settings/organization');
          }}
        />
        <Menu.Item
          name="Client Manager"
          active={selectedRouter === 'client-manager'}
          onClick={() => {
            setSelectedRouter('client-manager');
            history.push('/settings/client-manager');
          }}
        />
      </StyledMenu>
      <Switch>
        <Route path={'/settings/user'}>
          <ProfileSettings />
        </Route>
        <Route path={'/settings/organization'}>
          <Form></Form>
        </Route>
        <Route path={'/settings/client-manager'}>
          <ClientManager />
        </Route>
        <Route path={''}>
          <Redirect to="/settings/user" />
        </Route>
      </Switch>
    </>
  );
};
