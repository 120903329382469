import { Grid, Image, Header } from 'semantic-ui-react';
import styled from 'styled-components';
import NavigatorLogo from '../../assets/images/wheelhouse_navigator_logo.svg';
import CompassLogo from '../../assets/images/WHN_Logos_Compass.svg';
import SextantLogo from '../../assets/images/WHN_Logos_Sextant.svg';
import SonarLogo from '../../assets/images/WHN_Logos_Sonar.svg';
import { ActionCard } from '../../components';

const StyledDiv = styled.div`
  align-content: center;
  display: flex;
  flex-direction: column;
  height: 400px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%;
`;

const StyledImage = styled(Image)`
  width: 350px;
  margin: 0 auto;
`;

const StyledHeader = styled(Header)`
  font-weight: 400 !important;
`;

const StyledGrid = styled(Grid)`
  height: 100% !important;
  padding: 5em 0 0 0 !important;
`;

export const Dashboard = () => {
  return (
    <div id="userDashboard">
      <StyledDiv className="header-strip">
        <StyledImage src={NavigatorLogo} />
        <StyledHeader>
          Wheelhouse Navigator bridges the gaps between marketing technologies. With tools to help you better understand
          your data and users.
        </StyledHeader>
      </StyledDiv>
      <StyledGrid container stackable relaxed="very">
        <Grid.Row verticalAlign="middle">
          <ActionCard
            icon={SonarLogo}
            title="Launch Sonar"
            link="/sonar"
            subTitle="Detect and defend against data integrity issues with 24/7 automated
            flow-based monitoring and anomaly detection."
            buttonColor="#34AEC2"
          />
          <ActionCard
            icon={CompassLogo}
            title="Launch Compass"
            link="/compass"
            subTitle="Achieve clarity and insight for marketing performance
            reporting, analysis and decision-making."
            buttonColor="#007F71"
          />
          <ActionCard
            icon={SextantLogo}
            title="Launch Sextant"
            link="/sextant"
            subTitle="Superior attribution insights for critical marketing
            decisions. Attribution modeling and science and math."
            buttonColor="#3DBEAF"
          />
        </Grid.Row>
      </StyledGrid>
    </div>
  );
};
