import { useHistory } from 'react-router-dom';
import { Header, Image, Button } from 'semantic-ui-react';
import styled from 'styled-components';
import SonarLogo from '../assets/images/sonar-header-icon.svg';

const StyledHeaderFullDiv = styled.div`
  height: 215px;
  margin-bottom: 25px;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%;
  place-content: center;
  display: flex;
  align-items: center;
`;

const StyledHeaderSmallDiv = styled.div`
  padding: 15px;
  display: flex;
  margin-bottom: 25px;
`;

const StyledInnerDiv = styled.div`
  margin: 0 auto;
  display: flex;
`;

const StyledInnerHeader = styled(Header)`
  line-height: 0px;
  font-size: 28px;
  margin-left: 15px;
`;

const StyledLogoImage = styled(Image)`
  position: absolute !important;
  width: 277px;
  right: 2em;
  top: -1.8em;
  z-index: 0;
`;

const StyledSmallLogo = styled(Image)`
  height: 50px;
`;

export const SonarHeader = ({ headerSettings }) => {
  const history = useHistory();
  const { mode, title, subTitle } = headerSettings;

  return (
    <div>
      {mode == 'full' && (
        <StyledHeaderFullDiv className="sonar-header-strip">
          <Button basic content="Back" icon="arrow left" className="teal back-menu" onClick={() => history.goBack()} />
          <Header as="h1">
            {title}
            <Header.Subheader>{subTitle}</Header.Subheader>
          </Header>
          <StyledLogoImage src={SonarLogo} />
        </StyledHeaderFullDiv>
      )}
      {mode == 'small' && (
        <StyledHeaderSmallDiv className="sonar-header-strip">
          <Button basic content="Back" icon="arrow left" className="teal back-menu" onClick={() => history.goBack()} />
          <StyledInnerDiv>
            <StyledSmallLogo src={SonarLogo} />
            <StyledInnerHeader as="h1">Sonar</StyledInnerHeader>
          </StyledInnerDiv>
        </StyledHeaderSmallDiv>
      )}
    </div>
  );
};
