import { useQuery, useMutation } from '@apollo/client';
import { useState } from 'react';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import { Grid, Card, Button, Modal } from 'semantic-ui-react';
import { v4 } from 'uuid';
import { SonarHeader } from '../../../components';
import TestCards from '../Cards/TestCards';
import TestTemplateCard from '../Cards/TestTemplateCard';
import { ADD_NEW_TEST } from '../gql/mutations.gql';
import {
  GET_ACTIVE_USER,
  GET_CLIENT_BY_UUID,
  GET_TEST_TEMPLATES,
  GET_ACTIVE_TESTS,
  GET_INACTIVE_TESTS,
} from '../gql/queries.gql';
import '../css/sonar.css';

type ClientId = {
  client_id: string;
};

export const ClientLibrary = () => {
  const [templatesOpen, setTemplatesOpen] = useState(false);
  const { client_id } = useParams<ClientId>();
  const history = useHistory();

  const { loading, data } = useQuery(GET_CLIENT_BY_UUID, {
    variables: { client_id },
    pollInterval: 2000,
    fetchPolicy: 'no-cache',
  });

  const { loading: tempsLoading, data: tempsData } = useQuery(GET_TEST_TEMPLATES, {
    fetchPolicy: 'cache-first',
  });

  const { loading: l, data: current } = useQuery(GET_ACTIVE_USER, {
    fetchPolicy: 'no-cache',
  });

  const { loading: loadingTests, data: testData } = useQuery(GET_ACTIVE_TESTS, {
    variables: { client_id },
  });

  const { data: inactiveTestData } = useQuery(GET_INACTIVE_TESTS, {
    variables: { client_id },
  });

  // Update Test
  const [addTest, { loading: mutationLoading, error: mutationError }] = useMutation(ADD_NEW_TEST, {
    onCompleted: (data) => {
      if (data.insert_sonar_test.returning[0].client_tests[0].client_id) {
        const newPath = `/sonar/client/${data.insert_sonar_test.returning[0].client_tests[0].client_id}/edit_test/${data.insert_sonar_test.returning[0].test_id}`;
        history.push(newPath);
      }
    },
  });

  // Use Apollo's loading feature to prevent errors
  if (loading || l || tempsLoading || loadingTests) {
    return null;
  }

  //Creates a New Test
  const addNewTest = () => {
    const currentTime = new Date();
    addTest({
      variables: {
        client_id,
        test_id: v4(),
        created: currentTime.toISOString(),
        updated: currentTime.toISOString(),
      },
    });
  };

  // TODO: This should be refactored to run single instance tests... "ALL_TESTS" is less useful with the current tech.
  // Intention is to be able to run groups of tests at once...

  const renderClient = (client) => {
    const redirectString = `/sonar/client/${current.client_user[0].active_client_id}`;
    return (
      <div>
        {current.client_user[0].active_client_id !== client_id && <Redirect to={redirectString} />}

        <SonarHeader
          headerSettings={{
            mode: 'small',
            subTitle: 'Tracking critical data attribution and user flows.',
            title: 'SONAR',
          }}
        />

        <Grid container id="testGrid" stackable relaxed="very" style={{ marginTop: '50px' }}>
          <Grid.Row>
            <Grid.Column width={16} style={{ textAlign: 'right' }}>
              <Button onClick={addNewTest} color="teal" className="primary add-test">
                Create New Test
                {mutationLoading && <p>Loading...</p>}
                {mutationError && <p>Error :( Please try again</p>}
              </Button>
              <Button onClick={() => setTemplatesOpen(true)} color="teal" className="primary add-test-teplate">
                Create From Template
              </Button>
              <Modal onClose={() => setTemplatesOpen(false)} onOpen={() => setTemplatesOpen(true)} open={templatesOpen}>
                <Modal.Header>Please Choose a Test Template to Use</Modal.Header>
                <Modal.Content>
                  <Card.Group>
                    {tempsData?.sonar_test_template.map((testTemplate) => {
                      return (
                        <TestTemplateCard
                          key={testTemplate.test_template_id}
                          client_id={client_id}
                          test_template={testTemplate}
                        />
                      );
                    })}
                  </Card.Group>
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    content="Ok"
                    labelPosition="right"
                    icon="checkmark"
                    onClick={() => setTemplatesOpen(false)}
                    primary
                  />
                </Modal.Actions>
              </Modal>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              {testData?.client_client_by_pk?.client_client_test.length > 0 && (
                <>
                  <h4 style={{ fontWeight: 400 }}>{data.client_client_by_pk.name} - Active Tests</h4>
                  <TestCards client={client} testData={testData.client_client_by_pk.client_client_test} />
                </>
              )}

              {inactiveTestData?.client_client_by_pk?.client_client_test.length > 0 && (
                <>
                  <h4 style={{ fontWeight: 400 }}>Inactive Tests</h4>
                  <TestCards client={client} testData={inactiveTestData.client_client_by_pk.client_client_test} />
                </>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              {/* Disabling for now as this no longer aligns with a healthy Test lifecycle (see above TODO) */}
              {/* <Button primary client_id={ client.client_id } onClick={ runAllTests }>Run All Tests for { client.name }</Button> */}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  };

  return <div>{renderClient(data.client_client_by_pk)}</div>;
};
