import { useQuery, gql, useMutation } from '@apollo/client';
import ace from 'brace';
import { deepParseJson } from 'deep-parse-json';
import { JsonEditor as Editor } from 'jsoneditor-react';
import { useState } from 'react';
import { Form, Input, Button, Confirm, Icon, Accordion, Select } from 'semantic-ui-react';
import 'jsoneditor-react/es/editor.min.css';

import 'brace/mode/json';
import 'brace/theme/solarized_dark';

const GET_STEPS = gql`
  query getSteps($step_id: uuid!) {
    sonar_step(where: { step_id: { _eq: $step_id } }) {
      test_id
      step_id
      name
      type
      action_type
      sequence
      config
    }
  }
`;

const UPDATE_STEP = gql`
  mutation UpdateStep(
    $step_id: uuid!
    $name: String!
    $type: String!
    $action_type: String!
    $sequence: Int!
    $config: jsonb!
    $updated: timestamp!
  ) {
    update_sonar_step(
      where: { step_id: { _eq: $step_id } }
      _set: {
        name: $name
        type: $type
        action_type: $action_type
        sequence: $sequence
        config: $config
        updated: $updated
      }
    ) {
      returning {
        step_id
        name
        type
        action_type
        config
        updated
      }
    }
  }
`;

const DELETE_STEP = gql`
  mutation DeleteStep($step_id: uuid!) {
    delete_sonar_step(where: { step_id: { _eq: $step_id } }) {
      affected_rows
      returning {
        step_id
      }
    }
  }
`;

//Types
type Accordian = {
  activeIndex: number;
};

type Step = {
  action_type: string;
  config: string;
  name: string;
  sequence: number;
  step_id: string;
  type: string;
};

//These constants define our list options in the Step Form.
const stepTypes = [
  { key: 'nav', text: 'NAV', value: 'NAV' },
  { key: 'test', text: 'TEST', value: 'TEST' },
];

const navActions = [
  { key: 'click', text: 'Click', value: 'CLICK' },
  { key: 'click_nw', text: 'Click no Waiting', value: 'NO_WAIT_CLICK' },
  { key: 'amp_click', text: 'AMP Click', value: 'AMP_CLICK' },
  { key: 'goto', text: 'Go Directly to URL', value: 'GOTO' },
  { key: 'wait', text: 'Wait', value: 'WAIT' },
  { key: 'reload', text: 'Reload', value: 'RELOAD' },
];

const testActions = [
  { key: 'function', text: 'Sonar Test Function', value: 'FUNCTION' },
  { key: 'statcd', text: 'Status Code Check', value: 'STATUS_CODE' },
  { key: 'nrx', text: 'Network Request Regex', value: 'NET_REGEX' },
  {
    key: 'nrxpd',
    text: 'Network Post Data Regex',
    value: 'NET_REGEX_POSTDATA',
  },
  {
    key: 'nrxxhrpd',
    text: 'Network XHR Data Regex',
    value: 'NET_REGEX_XHR_POSTDATA',
  },
  { key: 'custom', text: 'Custom Javascript', value: 'CUSTOM_JS' },
];

// Setup default object test values
const stepDefaults = {
  TEST: {
    CUSTOM_JS: {
      jsObj:
        '{"functionName":"piwikInMaID","fn":async function piwikInMaID() { if(typeof(maID) !== "undefined") { if(maID.piwik !== ""){ return maID.piwik } else { return null }  }}}',
    },
    FUNCTION: {
      func: 'puppeteerController.getVisitorIdFromWh',
    },
    NET_REGEX: {
      regExMatchString: 'https://www\\.google-analytics\\.com/collect.*?cid=([0-9.]*).*$',
      matchCompareIndex: 1,
    },
    NET_REGEX_POSTDATA: {
      regExMatchString: 'https://tealium.wheelhousedmg.com/i.gif',
      netSearchValue: '"piwik_id":"([0-9a-fA-F]+)",',
      matchCompareIndex: 1,
    },
    NET_REGEX_XHR_POSTDATA: {
      regExMatchString: 'https://tealium.wheelhousedmg.com/i.gif',
      netSearchValue: '"piwik_id":"([0-9a-fA-F]+)",',
      matchCompareIndex: 1,
    },
    STATUS_CODE: {
      statusCodeIs: 200,
      urlToTest: 'https://www.mysite.com/',
    },
  },
  NAV: {
    AMP_CLICK: {
      shadowSelector: '#wmHost',
      clickSelector: 'a.all.btn.fill.telehealth',
    },
    CLICK: {
      selector: '.timeSlot-button',
    },
    GOTO: {
      url: 'https://www.mysite.com/path',
    },
    NO_WAIT_CLICK: {
      selector: '.js-schedule-widget-btn-main',
    },
    RELOAD: {},
    WAIT: {
      time_in_ms: 1000,
    },
  },
};

// let transitionVisibility = true;

type Props = {
  index: number;
  step_id: string;
};

interface confirmDialog {
  open: boolean;
  result: string;
}

interface ConfirmActionDialog {
  open: boolean;
  result: { action_type: string; config: string };
}

const StepForm = ({ step_id, index }: Props) => {
  const [accordianState, setAccordianState] = useState<Accordian>({
    activeIndex: -1,
  });

  const [confirmState, setConfirmState] = useState<confirmDialog>({
    open: false,
    result: '',
  });

  const [confirmActionModal, setConfirmActionModal] = useState<ConfirmActionDialog>({
    open: false,
    result: { action_type: '', config: '' },
  });

  //Setup Test State
  const [stepState, setStepState] = useState<Step>();

  //Get Test Apollo Query
  const {
    loading: queryLoading,
    error: queryError,
    data,
  } = useQuery(GET_STEPS, {
    variables: { step_id },
  });

  //Update Step
  const [updateStep, { loading: mutationLoading, error: mutationError }] = useMutation(UPDATE_STEP);

  //Delete Step
  const [deleteStep, { loading: mutationDeleteLoading, error: mutationDeleteError }] = useMutation(DELETE_STEP, {
    onCompleted: (data) => {
      if (data.delete_sonar_step.affected_rows) {
        //Brute force for now :P
        window.location.reload();
      }
    },
  });

  if (queryLoading) {
    return <p>Loading...</p>;
  }
  if (queryError) {
    return <p>Error :(</p>;
  }

  const handleAccordianClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = accordianState;
    const newIndex = activeIndex === index ? -1 : index;

    setAccordianState({ activeIndex: newIndex });
  };

  const handleDelete = () => {
    deleteStep({ variables: { step_id: stepState?.step_id } });
  };

  const handleCancel = (e, value) => {
    setConfirmState({ open: false, result: value });
  };

  const handleActionChange = (e, { value }) => {
    // Checking if the step's JSON object has been set and if not, set it.
    if (Object.keys(deepParseJson(stepState.config)).length === 0) {
      setStepState((prev) => {
        return {
          ...prev,
          action_type: value,
          config: JSON.stringify(stepDefaults[stepState.type][value]),
        };
      });
      jsonEditorRef.jsonEditor.setText(JSON.stringify(stepDefaults[stepState.type][value], null, 2));
      return;
    }

    let isDefault = false;

    // Comparing steps current JSON object to the step default objects
    for (const [a, b] of Object.entries(stepDefaults)) {
      for (const [c, d] of Object.entries(b)) {
        if (JSON.stringify(d) === stepState.config) {
          isDefault = true;
          break;
        }
      }
    }

    if (isDefault) {
      setStepState({
        ...stepState,
        action_type: value,
        config: JSON.stringify(stepDefaults[stepState.type][value]),
      });
      jsonEditorRef.jsonEditor.setText(JSON.stringify(stepDefaults[stepState.type][value], null, 2));
    }

    if (!isDefault) {
      setConfirmActionModal({
        open: true,
        result: { action_type: value, config: JSON.stringify(stepDefaults[stepState.type][value], null, 2) },
      });
    }
  };

  const handleActionChangeConfirm = () => {
    setStepState({
      ...stepState,
      action_type: confirmActionModal.result.action_type,
      config: confirmActionModal.result.config,
    });
    jsonEditorRef.jsonEditor.setText(confirmActionModal.result.config);

    setConfirmActionModal({ ...confirmActionModal, open: false });
  };

  const handleActionChangeCancel = () => {
    setConfirmActionModal({ ...confirmActionModal, open: false });
  };

  const { activeIndex } = accordianState;

  // eslint-disable-next-line array-callback-return
  data.sonar_step.map((step: Step): void => {
    if (!stepState) {
      setStepState(step);
    }
  });

  if (!stepState) {
    return <p>Loading...</p>;
  }

  const idPanel = [
    {
      key: 'step_id',
      title: 'Step ID',
      content: {
        as: Form.Input,
        value: stepState.step_id,
      },
    },
  ];

  let jsonEditorRef: Editor | null;

  // Ref for JSON Editor because updating Editor value doesn't trigger rerender
  const setJsonEditorRef = (element) => {
    if (element) {
      jsonEditorRef = element;
    }
  };

  return (
    <div>
      <Accordion.Title
        active={activeIndex === index}
        index={index}
        title={stepState.step_id}
        onClick={handleAccordianClick}
      >
        <Icon name="dropdown" />
        {stepState.name}
      </Accordion.Title>
      <Accordion.Content active={activeIndex === index}>
        <Form
          onSubmit={(e) => {
            const updated = new Date();
            e.preventDefault();
            updateStep({
              variables: {
                action_type: stepState.action_type,
                config: stepState.config,
                name: stepState.name,
                sequence: parseInt(stepState.sequence.toString(), 10),
                step_id: stepState.step_id,
                type: stepState.type,
                updated,
              },
            });
          }}
        >
          <Form.Group widths="equal">
            <Form.Field
              id="form-input-control-test-step-name"
              control={Input}
              value={stepState.name}
              label="Step Name"
              placeholder="Step name"
              onChange={(e, { value }) => setStepState({ ...stepState, name: value })}
            />
            <Form.Field
              id="form-input-control-test-step-sequence"
              control={Input}
              width="five"
              label="Step Sequence"
              value={stepState.sequence}
              onChange={(e, { value }) => setStepState({ ...stepState, sequence: value })}
            />
          </Form.Group>
          <Form.Group>
            <Form.Field
              id="form-input-control-test-step-type"
              control={Select}
              label="Step Type"
              value={stepState.type}
              options={stepTypes}
              onChange={(e, { value }) => setStepState({ ...stepState, type: value })}
            />
            <Form.Field
              id="form-input-control-test-step-action-type"
              control={Select}
              label="Action Type"
              value={stepState.action_type}
              options={stepState.type === 'NAV' ? navActions : testActions}
              onChange={handleActionChange}
            />
          </Form.Group>
          <Editor
            id="form-textarea-control-test-step-config"
            value={
              Object.keys(deepParseJson(stepState.config)).length === 0
                ? stepDefaults[stepState.type][stepState.action_type]
                : deepParseJson(stepState.config)
            }
            ref={setJsonEditorRef}
            mode="code"
            ace={ace}
            theme="ace/theme/solarized_dark"
            onChange={(json) => {
              if (json) {
                setStepState({
                  action_type: stepState.action_type,
                  config: JSON.stringify(json),
                  name: stepState.name,
                  sequence: stepState.sequence,
                  step_id: stepState.step_id,
                  type: stepState.type,
                });
                jsonEditorRef.setValue(JSON.stringify(json, null, 2));
              }
            }}
          />
          <Accordion panels={idPanel} />
          <p></p>
          <Button primary type="submit">
            Save Step
          </Button>
          <Button
            color="red"
            onClick={() => {
              setConfirmState({ ...confirmState, open: true });
            }}
          >
            Delete Step
          </Button>
          <Confirm
            open={confirmState.open}
            content="Permanently delete this Step?"
            onCancel={handleCancel}
            onConfirm={handleDelete}
            negative
          />
          {/* Confirm change of JSON if its been edited */}
          <Confirm
            open={confirmActionModal.open}
            header="Changing action types clears any current configuration changes."
            content="Are you sure you want to change the action type?"
            onCancel={handleActionChangeCancel}
            onConfirm={handleActionChangeConfirm}
          />
          {mutationLoading && <p>Loading...</p>}
          {mutationError && <p>Error :( Please try again</p>}
          {mutationDeleteLoading && <p>Loading...</p>}
          {mutationDeleteError && <p>Error :( Please try again</p>}
        </Form>
      </Accordion.Content>
    </div>
  );
};

export default StepForm;
