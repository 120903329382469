export const colorPal = [
  '#3DBEAF',
  '#007F71',
  '#FFB500',
  '#6E7277',
  '#939498',
  '#B2B5B6',
  '#63D67D',
  '#7BB6CC',
  '#0B95C3',
  '#227BBC',
  '#465CD6',
];

export const modelMap = {
  first: {
    key: 'model_first',
    label: 'First Touch',
  },
  last: {
    key: 'model_last',
    label: 'Last Touch',
  },
  last_ndr: {
    key: 'model_lastNdr',
    label: 'Last Non-Direct Touch',
  },
  linear: {
    key: 'model_linear',
    label: 'Linear',
  },
  'O(1) Removal Effect': {
    key: 'model_markov',
    label: 'Markov R1 RE',
  },
  'O(1) Score': {
    key: 'model_markov',
    label: 'Markov R1',
  },
  'O(2) Removal Effect': {
    key: 'model_markov',
    label: 'Markov R2 RE',
  },
  'O(2) Score': {
    key: 'model_markov',
    label: 'Markov R2',
  },
};
