import { useQuery, useMutation } from '@apollo/client';
import ace from 'brace';
import { isValidCron } from 'cron-validator';
import { deepParseJson } from 'deep-parse-json';
import { JsonEditor as Editor } from 'jsoneditor-react';
import { useState } from 'react';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { Grid, Form, Input, Button, TextArea, Accordion, Confirm, Select, Checkbox } from 'semantic-ui-react';

import 'brace/mode/json';
import 'brace/theme/solarized_dark';
import { v4 } from 'uuid';

import { SonarHeader } from '../../../components';
import {
  ADD_NEW_ASSERTION,
  ADD_NEW_ESCALATION,
  ADD_NEW_STEP,
  DELETE_TEST,
  UPDATE_TEST,
} from '../../../graphql/mutations';
import { GET_CLIENT_TEST } from '../../../graphql/queries';
import {
  ClientTest,
  ConfirmDialog,
  FormValidation,
  ParamsType,
  TestAssertion,
  TestEscalation,
  TestStep,
} from '../../../types';
import AssertionForm from './AssertionForm';
import EscalationForm from './EscalationForm';
import StepForm from './StepForm';

import '../css/sonar.css';
import './css/form.css';
import 'jsoneditor-react/es/editor.min.css';

const TestForm = () => {
  const { client_id, test_id } = useParams<ParamsType>();
  const history = useHistory();
  const redirectString = `/sonar/client/${client_id}/library/`;

  //Setup Test State
  const [testState, setTestState] = useState<ClientTest>();
  const [validationSate, setValidationSate] = useState<FormValidation>({
    cron_schedule: {
      error: false,
      message: '',
    },
  });

  //Dialog State
  const [confirmState, setConfirmState] = useState<ConfirmDialog>({
    open: false,
    result: '',
  });

  //Get Test Apollo Query
  const {
    loading: testQueryLoading,
    error: testQueryError,
    data,
  } = useQuery(GET_CLIENT_TEST, {
    variables: { client_id, test_id },
  });

  //Update Test
  const [updateTest, { loading: mutationLoading, error: mutationError }] = useMutation(UPDATE_TEST, {
    refetchQueries: [
      {
        query: GET_CLIENT_TEST,
        variables: { client_id, test_id },
      },
    ],
  });

  //Add Step
  const [addStep] = useMutation(ADD_NEW_STEP, {
    onCompleted: (data) => {
      if (data.insert_sonar_step.returning[0].step_id) {
        //Brute force for now :P
        window.location.reload();
      }
    },
  });

  //Add Assertion
  const [addAssertion] = useMutation(ADD_NEW_ASSERTION, {
    onCompleted: (data) => {
      if (data.insert_sonar_assertion.returning[0].assertion_id) {
        //Brute force for now :P
        window.location.reload();
      }
    },
  });

  //Add Escalation
  const [addEscalation] = useMutation(ADD_NEW_ESCALATION, {
    onCompleted: (data) => {
      if (data.insert_sonar_escalation.returning[0].escalation_id) {
        //Brute force for now :P
        window.location.reload();
      }
    },
  });

  //Delete Test
  const [deleteTest, { loading: mutationDeleteLoading, error: mutationDeleteError }] = useMutation(DELETE_TEST, {
    onCompleted: (data) => {
      if (data.delete_sonar_test.affected_rows) {
        history.push(`/sonar/client/${data.delete_sonar_client_test.returning[0].client_id}/library/`);
        //Brute force state refresh for now
        window.location.reload();
      }
    },
  });

  const defaultNetworkConfig = {
    NET_CONFIG: {
      filter: {
        on: 'request_url',
        expression: 'RegExp(/google/)',
      },
      captureRequests: true,
    },
  };

  if (testQueryLoading) {
    return <p>Loading...</p>;
  }
  if (testQueryError) {
    return <p>Client Test Error :(</p>;
  }

  if (data.sonar_client_test.length === 0) {
    return <Redirect to={redirectString} />;
  }

  //We are mapping the test to State for ease of use.
  // eslint-disable-next-line array-callback-return
  data.sonar_client_test.map((client_test: ClientTest) => {
    if (!testState) {
      setTestState(client_test);
    }
  });

  if (!testState) {
    return <p>Loading...</p>;
  }

  const stepsSorted = testState.test.steps
    ?.slice()
    .sort((a: TestStep, b: TestStep) => (a.sequence > b.sequence ? 1 : -1));

  const addNewStep = () => {
    const currentTime = new Date();
    const sequenceNum = stepsSorted.length + 1 || 1;

    addStep({
      variables: {
        created: currentTime.toISOString(),
        sequence: sequenceNum,
        step_id: v4(),
        test_id: testState.test.test_id,
        updated: currentTime.toISOString(),
      },
    });
  };

  const addNewAssertion = () => {
    const currentTime = new Date();
    addAssertion({
      variables: {
        test_id: testState.test.test_id,
        assertion_id: v4(),
        created: currentTime.toISOString(),
        updated: currentTime.toISOString(),
      },
    });
  };

  const addNewEscalation = () => {
    const currentTime = new Date();
    addEscalation({
      variables: {
        test_id: testState.test.test_id,
        escalation_id: v4(),
        created: currentTime.toISOString(),
        updated: currentTime.toISOString(),
      },
    });
  };

  const handleDelete = () => {
    deleteTest({
      variables: {
        client_id: testState.client_id,
        test_id: testState.test.test_id,
      },
    });
  };

  const handleCancel = (e, value) => {
    setConfirmState({ open: false, result: value });
  };

  const validateFormFields = (testState) => {
    //If value is null, skip validation
    if (testState.test.cron_schedule) {
      if (!validateCron(testState.test.cron_schedule)) {
        return false;
      }
    }
    return true;
  };

  const validateCron = (value) => {
    //Reset validation state
    setValidationSate({
      cron_schedule: {
        error: false,
        message: '',
      },
    });

    //Run against cron-validator package
    if (!isValidCron(value, { seconds: false })) {
      setValidationSate({
        cron_schedule: {
          error: true,
          message: 'Cron is not valid. Seconds are not allowed',
        },
      });
      return false;
    }

    const characters = value.split(/\s+/);
    let minuteChar = characters.shift();
    const limit = 30;

    //if no other cron params are set
    if (characters.every((ele) => ele === '*')) {
      //check if minutes are split
      const splitMinute = minuteChar.match(/^\*\/(\d+)$/);
      if (splitMinute) {
        minuteChar = splitMinute[1];
      }
      if (minuteChar < limit || minuteChar === '*') {
        setValidationSate({
          cron_schedule: {
            error: true,
            message: 'There must be 30 minutes between a cron',
          },
        });
        return false;
      }
    }
    return true;
  };

  const testTypes = [
    { key: 'analyticsint', text: 'Analytics', value: 'ANALYTICS' },
    { key: 'userflow', text: 'User Flow', value: 'USER_FLOW' },
    { key: 'conversionflow', text: 'Conversion Flow', value: 'CONVERSION_FLOW' },
    { key: 'hipaacomp', text: 'HIPAA Compliance', value: 'HIPAA_COMPLIANCE' },
    {
      key: 'campaignmgmt',
      text: 'Campaign Management',
      value: 'CAMPAIGN_MGMT',
    },
  ];

  return (
    <div>
      <SonarHeader
        headerSettings={{
          mode: 'small',
          subTitle: testState.test.description,
          title: testState.test.name,
        }}
      />

      <Grid container id="testForm" stackable relaxed="very" style={{ marginTop: '50px' }}>
        <Grid.Row>
          <Grid.Column width={8}>
            <h3>Edit {testState.test.name || 'Test'}</h3>
            <Form
              onSubmit={(e) => {
                const updated = new Date();
                e.preventDefault();

                if (!validateFormFields(testState)) {
                  return false;
                }

                updateTest({
                  variables: {
                    cron_schedule: testState.test.cron_schedule,
                    description: testState.test.description,
                    enabled: testState.test.enabled,
                    log_bucket: testState.test.log_bucket,
                    log_path: testState.test.log_path,
                    name: testState.test.name,
                    network_capture_config: testState.test.network_capture_config,
                    start_url: testState.test.start_url,
                    test_id: testState.test.test_id,
                    test_type: testState.test.test_type,
                    updated: updated.toISOString(),
                  },
                });
              }}
            >
              <Form.Field
                id="form-textarea-control-test-enabled"
                control={Checkbox}
                label="Enabled"
                defaultChecked={testState.test.enabled}
                onChange={(e) =>
                  setTestState({
                    client_id: testState.client_id,
                    test: {
                      assertions: testState.test.assertions,
                      cron_schedule: testState.test.cron_schedule,
                      description: testState.test.description,
                      enabled: e.target.checked,
                      escalations: testState.test.escalations,
                      log_bucket: testState.test.log_bucket,
                      log_path: testState.test.log_path,
                      name: testState.test.name,
                      network_capture_config: testState.test.network_capture_config,
                      start_url: testState.test.start_url,
                      steps: testState.test.steps,
                      test_id: testState.test.test_id,
                      test_type: testState.test.test_type,
                    },
                  })
                }
                toggle
              />
              <Form.Field
                id="form-input-control-test-name"
                control={Input}
                value={testState.test.name || ''}
                label="Name"
                placeholder="Test name"
                onChange={(e, { value }) =>
                  setTestState({
                    client_id: testState.client_id,
                    test: {
                      assertions: testState.test.assertions,
                      cron_schedule: testState.test.cron_schedule,
                      description: testState.test.description,
                      enabled: testState.test.enabled,
                      escalations: testState.test.escalations,
                      log_bucket: testState.test.log_bucket,
                      log_path: testState.test.log_path,
                      name: value,
                      network_capture_config: testState.test.network_capture_config,
                      start_url: testState.test.start_url,
                      steps: testState.test.steps,
                      test_id: testState.test.test_id,
                      test_type: testState.test.test_type,
                    },
                  })
                }
              />
              <Form.Field
                id="form-textarea-control-test-description"
                control={TextArea}
                value={testState.test.description}
                label="Description"
                placeholder="Test description"
                onChange={(e, { value }) =>
                  setTestState({
                    client_id: testState.client_id,
                    test: {
                      assertions: testState.test.assertions,
                      cron_schedule: testState.test.cron_schedule,
                      description: value,
                      enabled: testState.test.enabled,
                      escalations: testState.test.escalations,
                      log_bucket: testState.test.log_bucket,
                      log_path: testState.test.log_path,
                      name: testState.test.name,
                      network_capture_config: testState.test.network_capture_config,
                      start_url: testState.test.start_url,
                      steps: testState.test.steps,
                      test_id: testState.test.test_id,
                      test_type: testState.test.test_type,
                    },
                  })
                }
              />
              <Form.Field
                id="form-textarea-control-test-start-url"
                control={Input}
                value={testState.test.start_url}
                label="Start URL"
                placeholder="https://page-to-test.com"
                onChange={(e, { value }) =>
                  setTestState({
                    client_id: testState.client_id,
                    test: {
                      assertions: testState.test.assertions,
                      cron_schedule: testState.test.cron_schedule,
                      description: testState.test.description,
                      enabled: testState.test.enabled,
                      escalations: testState.test.escalations,
                      log_bucket: testState.test.log_bucket,
                      log_path: testState.test.log_path,
                      name: testState.test.name,
                      network_capture_config: testState.test.network_capture_config,
                      start_url: value,
                      steps: testState.test.steps,
                      test_id: testState.test.test_id,
                      test_type: testState.test.test_type,
                    },
                  })
                }
              />
              <Form.Field
                id="form-textarea-control-test-cron-schedule"
                control={Input}
                value={testState.test.cron_schedule}
                label="Cron Schedule"
                placeholder="* * * * *"
                error={validationSate.cron_schedule.error ? { content: validationSate.cron_schedule.message } : false}
                onChange={(e, { value }) =>
                  setTestState({
                    client_id: testState.client_id,
                    test: {
                      assertions: testState.test.assertions,
                      cron_schedule: value,
                      description: testState.test.description,
                      enabled: testState.test.enabled,
                      escalations: testState.test.escalations,
                      log_bucket: testState.test.log_bucket,
                      log_path: testState.test.log_path,
                      name: testState.test.name,
                      network_capture_config: testState.test.network_capture_config,
                      start_url: testState.test.start_url,
                      steps: testState.test.steps,
                      test_id: testState.test.test_id,
                      test_type: testState.test.test_type,
                    },
                  })
                }
              />
              <Form.Field
                id="form-textarea-control-test-type"
                control={Select}
                label="Test Type"
                value={testState.test.test_type}
                options={testTypes}
                onChange={(e, { value }) =>
                  setTestState({
                    client_id: testState.client_id,
                    test: {
                      assertions: testState.test.assertions,
                      cron_schedule: testState.test.cron_schedule,
                      description: testState.test.description,
                      enabled: testState.test.enabled,
                      escalations: testState.test.escalations,
                      log_bucket: testState.test.log_bucket,
                      log_path: testState.test.log_path,
                      name: testState.test.name,
                      network_capture_config: testState.test.network_capture_config,
                      start_url: testState.test.start_url,
                      steps: testState.test.steps,
                      test_id: testState.test.test_id,
                      test_type: value,
                    },
                  })
                }
              />
              <Editor
                value={
                  !testState.test.network_capture_config
                    ? deepParseJson(defaultNetworkConfig.NET_CONFIG)
                    : deepParseJson(testState.test.network_capture_config)
                }
                mode="code"
                ace={ace}
                theme="ace/theme/solarized_dark"
                onChange={(json) => {
                  if (json) {
                    setTestState({
                      client_id: testState.client_id,
                      test: {
                        assertions: testState.test.assertions,
                        cron_schedule: testState.test.cron_schedule,
                        description: testState.test.description,
                        enabled: testState.test.enabled,
                        escalations: testState.test.escalations,
                        log_bucket: testState.test.log_bucket,
                        log_path: testState.test.log_path,
                        name: testState.test.name,
                        network_capture_config: json,
                        start_url: testState.test.start_url,
                        steps: testState.test.steps,
                        test_id: testState.test.test_id,
                        test_type: testState.test.test_type,
                      },
                    });
                  }
                }}
              />
              <p></p>
              <Form.Group>
                <Form.Field
                  id="form-button-control-public-save-test"
                  control={Button}
                  type="submit"
                  content="Save Test"
                  primary
                />
                {mutationLoading && <p>Loading...</p>}
                {mutationError && <p>Error :( Please try again</p>}
                <Button
                  color="red"
                  onClick={() => {
                    setConfirmState({ ...confirmState, open: true });
                  }}
                >
                  Delete Test
                </Button>
                <Confirm
                  open={confirmState.open}
                  content="Permanently delete this Test?"
                  onCancel={handleCancel}
                  onConfirm={handleDelete}
                  negative
                />
                {mutationDeleteLoading && <p>Loading...</p>}
                {mutationDeleteError && <p>Error :( Please try again</p>}
              </Form.Group>
            </Form>
          </Grid.Column>
          <Grid.Column width={8}>
            {stepsSorted.length > 0 && (
              <Grid.Row>
                <h3>Edit Steps</h3>
                <Accordion fluid styled as={Form.Field}>
                  {stepsSorted.map((step: TestStep, index) => (
                    <StepForm key={step.step_id} step_id={step.step_id} index={index} />
                  ))}
                </Accordion>
              </Grid.Row>
            )}
            <p></p>
            <Button as={Form.Field} secondary onClick={addNewStep}>
              Add New Step
            </Button>
            {testState.test.assertions?.length > 0 && (
              <Grid.Row>
                <p></p>
                <h3>Edit Assertions</h3>
                <Accordion fluid styled as={Form.Field}>
                  {testState.test.assertions?.map((assertion: TestAssertion, index) => (
                    <AssertionForm key={assertion.assertion_id} assertion_id={assertion.assertion_id} index={index} />
                  ))}
                </Accordion>
              </Grid.Row>
            )}
            <p></p>
            <Button as={Form.Field} secondary onClick={addNewAssertion}>
              Add New Assertion
            </Button>
            {testState.test.escalations?.length > 0 && (
              <Grid.Row>
                <p></p>
                <h3>Edit Escalations</h3>
                <Accordion fluid styled as={Form.Field}>
                  {testState.test.escalations?.map((escalation: TestEscalation, index) => (
                    <EscalationForm
                      key={escalation.escalation_id}
                      escalation_id={escalation.escalation_id}
                      index={index}
                    />
                  ))}
                </Accordion>
              </Grid.Row>
            )}
            <p></p>
            <Button as={Form.Field} secondary onClick={addNewEscalation}>
              Add New Escalation
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default TestForm;
