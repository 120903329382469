import { Heatmap } from 'reaviz';
import { Grid, Header } from 'semantic-ui-react';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { colorPal } from '../../../constants';

const ModelComparison = ({ csv, graphStates, transitionMatrix }) => {
  const heatmapData = null;
  // Create graphviz DOT view
  const transitionmatrix = transitionMatrix
    .slice(1, -2)
    .split('[')
    .slice(2)
    .map((r) =>
      r
        .replace(']', '')
        .replace(',\n  ', '')
        .split(',')
        .map((v) => {
          if (isNaN(parseFloat(v))) {
            if (v === 'inf') {
              return 1;
            } else if (v === 'nan') {
              return 0;
            }
            return 0;
          }
          return parseFloat(v);
        }),
    );
  const dot = ['digraph {'];
  for (const chan of graphStates) {
    // Handle graphviz DOT
    const d = `"${chan}" [label="${chan}" fillcolor="${
      colorPal[graphStates.indexOf(chan)]
    }" style=filled fontname="helvetica"]`;
    dot.push(d);
  }
  for (let row = 0; row < transitionmatrix.length; row++) {
    for (let col = 0; col < graphStates.length; col++) {
      const t_val = transitionmatrix[row][col];
      if (t_val > 0) {
        dot.push(`"${graphStates[row]}" -> "${graphStates[col]}" [label=${(t_val * 100).toFixed(2)}]`);
      }
    }
  }
  dot.push('\n}');

  // Calculate journey metrics
  let beforeConv = 0;
  let interactions = 0;

  for (const row of csv.split('\n').slice(1)) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    beforeConv += parseInt(row.split(',')[3]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interactions += row.split(',')[0].split('>').length * parseInt(row.split(',')[3]);
  }

  return (
    <>
      <Grid.Row>
        <Grid.Column>
          <Header as="h3">
            Journey Analysis
            <Header.Subheader>
              Using the Variable Order Markov model, visualize the relationship between channels based on the different
              Journeys customers take to conversion.
            </Header.Subheader>
          </Header>
          {heatmapData ? (
            <>
              <Heatmap height={600} width={600} data={heatmapData} />
            </>
          ) : null}
        </Grid.Column>
      </Grid.Row>
    </>
  );
};

export default ModelComparison;
