import { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Grid, Header, Form, Table } from 'semantic-ui-react';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { colorPal, modelMap } from '../../../constants';

const ModelComparison: React.FC<{
  csv: string;
  data;
  graphStates: string[];
  transitionMatrix: string;
  // eslint-disable-next-line react/function-component-definition
}> = ({ data, csv, graphStates, transitionMatrix }) => {
  const [state, setState] = useState<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    heatmapData?: any;
    holdout?: {
      afterConv?: number;
      afterRows?: number;
      csv?: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      excluded?: any;
      loading?: boolean;
      show?: boolean;
    };
    model_first: boolean;
    model_last: boolean;
    model_lastNdr: boolean;
    model_linear: boolean;
    model_markov: boolean;
    model_shapley: boolean;
    model_survival: boolean;
    stacked: boolean;
  }>({
    model_first: true,
    model_last: true,
    model_lastNdr: true,
    model_linear: true,
    model_markov: true,
    model_shapley: false,
    model_survival: false,
    stacked: false,
  });
  const chartData = [];
  let channels = [];
  const tableHeaders = [];
  const parsedData = JSON.parse(data);
  for (const model in parsedData) {
    if (modelMap[model] && state[modelMap[model].key]) {
      if (!modelMap[model].label.includes('RE')) {
        tableHeaders.push(modelMap[model].label);
      }
      chartData.push({
        name: modelMap[model].label,
        ...parsedData[model],
      });
    }

    channels = [...channels, ...Object.keys(parsedData[model])];
  }
  channels = Array.from(new Set(channels)).filter((c) => c !== 'Start' && c !== 'Conversion');

  const tableRows = [];
  for (const chan of channels) {
    // Handle Table rows
    const r = [chan];
    for (const model of chartData.filter((x) => !x.name.includes('RE'))) {
      r.push((model[chan] * 100).toFixed(2));
    }
    tableRows.push(r);
  }

  // Create graphviz DOT view
  const transitionmatrix = transitionMatrix

    .slice(1, -2)
    .split('[')
    .slice(2)
    .map((r) =>
      r
        .replace(']', '')
        .replace(',\n  ', '')
        .split(',')
        .map((v) => {
          if (isNaN(parseFloat(v))) {
            if (v === 'inf') {
              return 1;
            } else if (v === 'nan') {
              return 0;
            }
            return 0;
          }
          return parseFloat(v);
        }),
    );
  const dot = ['digraph {'];
  for (const chan of graphStates) {
    // Handle graphviz DOT
    const d = `"${chan}" [label="${chan}" fillcolor="${
      colorPal[graphStates.indexOf(chan)]
    }" style=filled fontname="helvetica"]`;
    dot.push(d);
  }
  for (let row = 0; row < transitionmatrix.length; row++) {
    for (let col = 0; col < graphStates.length; col++) {
      const t_val = transitionmatrix[row][col];
      if (t_val > 0) {
        dot.push(`"${graphStates[row]}" -> "${graphStates[col]}" [label=${(t_val * 100).toFixed(2)}]`);
      }
    }
  }
  dot.push('\n}');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const final_dot = dot.join('\n');

  // Calculate journey metrics
  let beforeConv = 0;
  let interactions = 0;

  const rows = csv.split('\n').length;

  for (const row of csv.split('\n').slice(1)) {
    beforeConv += parseInt(row.split(',')[3]);
    interactions += row.split(',')[0].split('>').length * parseInt(row.split(',')[3]);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const beforeRows = rows;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const avgPathLength = (interactions / beforeConv).toFixed(2);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const totalInteractions = interactions;

  const handleChange = (e, data) =>
    setState({
      ...state,
      [data.name]: data.value ? data.value : !state[data.name],
    });

  return (
    <>
      <Grid.Row>
        <Grid.Column>
          <Header as="h3">Model Credit Allocation</Header>

          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              width={500}
              height={300}
              data={chartData.filter((k) => !k.name.includes('RE'))}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis domain={[0, 'dataMax']} />
              <Tooltip formatter={(value, label) => [`${Math.round(value * 10000) / 100}%`, label]} />
              {Object.keys(chartData[Object.keys(chartData)[0]])
                .filter((d) => d !== 'name' && d !== 'Start' && d !== 'Conversion')
                .map((d, i) => (
                  <Bar key={i} dataKey={d} stackId={state.stacked ? 'a' : null} fill={colorPal[i]} />
                ))}
            </BarChart>
          </ResponsiveContainer>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              width={500}
              height={300}
              data={chartData.filter((k) => !k.name.includes('RE'))}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis domain={[0, 'dataMax']} />
              <Tooltip formatter={(value, label) => [`${Math.round(value * 10000) / 100}%`, label]} />
              <Legend />
              {Object.keys(chartData[Object.keys(chartData)[0]])
                .filter((d) => d !== 'name' && d !== 'Start' && d !== 'Conversion')
                .map((d, i) => (
                  <Bar key={i} dataKey={d} stackId={'a'} fill={colorPal[i]} />
                ))}
            </BarChart>
          </ResponsiveContainer>

          <Header as="h4">Show/Hide Models</Header>
          <Form>
            <Form.Group inline>
              <label>Single Touch</label>
              <Form.Checkbox
                name="model_first"
                onChange={handleChange}
                checked={state.model_first}
                toggle
                label="First Touch"
              />
              <Form.Checkbox
                name="model_last"
                onChange={handleChange}
                checked={state.model_last}
                toggle
                label="Last Touch"
              />
              <Form.Checkbox
                name="model_lastNdr"
                onChange={handleChange}
                checked={state.model_lastNdr}
                toggle
                label="Last Non-Direct Touch"
              />
            </Form.Group>
            <Form.Group inline>
              <label>Positional</label>
              <Form.Checkbox
                name="model_linear"
                onChange={handleChange}
                checked={state.model_linear}
                toggle
                label="Linear"
              />
            </Form.Group>
            <Form.Group inline>
              <label>Data Driven</label>
              <Form.Checkbox
                name="model_markov"
                onChange={handleChange}
                checked={state.model_markov}
                toggle
                label="Variable Order Markov"
              />
              <Form.Checkbox
                disabled
                name="model_shapley"
                onChange={handleChange}
                checked={state.model_shapley}
                toggle
                label="Shapley Value"
              />
              <Form.Checkbox
                disabled
                name="model_survival"
                onChange={handleChange}
                checked={state.model_survival}
                toggle
                label="Survival Model"
              />
            </Form.Group>
          </Form>

          <Table id="reportTable" definition selectable celled unstackable sortable style={{ marginTop: '25px' }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Channel</Table.HeaderCell>
                {tableHeaders.map((v) => (
                  <Table.HeaderCell key={v}>{v}</Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {tableRows.map((v, i) => (
                <Table.Row key={i}>
                  {v.map((val, j) => (
                    <Table.Cell key={j}> {val} </Table.Cell>
                  ))}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
    </>
  );
};

export default ModelComparison;
