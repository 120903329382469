import { Auth } from 'aws-amplify';
import { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Button, Form, Header, Message, Segment } from 'semantic-ui-react';
import { ResetCredentials, PasswordResetFormProps, ResetResponse } from '../../types';

const PasswordResetForm = ({ user, show_code }: PasswordResetFormProps) => {
  const [redirectCountdown, setRedirectCountdown] = useState(5);
  const [formInvalid, setFormInvalid] = useState(true);

  const [userResetCredentials, setUserResetCredentials] = useState<ResetCredentials>({
    code: '',
    email: '',
    new_password: '',
    new_password_again: '',
  });

  const [resetResponse, setResetResponse] = useState<ResetResponse>({
    show_message: false,
    error: false,
    message: '',
  });

  const history = useHistory();

  const setNewPassword = (e, data, user) => {
    //TODO: Add client validation
    const { requiredAttributes } = user.challengeParam;
    Auth.completeNewPassword(
      user, // the Cognito User Object
      userResetCredentials.new_password.trim(), // the new password
      requiredAttributes,
    )
      .then(() => {
        history.push('/dashboard');
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log('Auth Reset Error');
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };

  const setNewPasswordwCode = () => {
    //TODO: Add validation
    Auth.forgotPasswordSubmit(
      userResetCredentials.email.trim(),
      userResetCredentials.code.trim(),
      userResetCredentials.new_password.trim(),
    )
      .then(() => {
        setResetResponse({
          show_message: true,
          error: false,
          message: 'Your password was reset successfully!',
        });
      })
      .catch((err) => {
        setResetResponse({
          show_message: true,
          error: true,
          message: err.message,
        });
      });
  };

  //Super-basic validation for now TODO: More advanced validator
  if (
    userResetCredentials.new_password !== '' &&
    userResetCredentials.new_password === userResetCredentials.new_password_again &&
    formInvalid
  ) {
    setFormInvalid(false);
  }

  useEffect(() => {
    let redirectInterval;
    let redirectTimeout;
    if (resetResponse.show_message && !resetResponse.error) {
      redirectInterval = setInterval(() => {
        setRedirectCountdown((prev) => prev - 1);
      }, 1000);
      redirectTimeout = setTimeout(() => {
        history.push('/');
      }, 5000);
    }
    return () => {
      clearInterval(redirectInterval);
      clearTimeout(redirectTimeout);
    };
  }, [history, resetResponse]);

  return (
    <div>
      <Header as="h2" textAlign="center" style={{ color: '#808080' }}>
        Please enter a new password:
      </Header>
      {resetResponse.show_message && resetResponse.error && <Message negative>{resetResponse.message}</Message>}
      {resetResponse.show_message && !resetResponse.error && (
        <Message positive>
          {resetResponse.message}
          <br />
          <p>
            You will be redirected to the login page in <strong>{redirectCountdown}</strong> seconds to login with your
            new credentials.
          </p>
          <Link to="/">Go back to the login page</Link>
        </Message>
      )}
      <Form
        size="large"
        onSubmit={(e, data) => {
          if (show_code) {
            setNewPasswordwCode();
          } else {
            setNewPassword(e, data, user);
          }
        }}
      >
        <Segment stacked>
          {show_code && (
            <Form.Input
              fluid
              icon="asterisk"
              iconPosition="left"
              value={userResetCredentials.code}
              onChange={(e, { value }) =>
                setUserResetCredentials({
                  ...userResetCredentials,
                  code: value,
                })
              }
              placeholder="Reset Code"
            />
          )}
          {show_code && (
            <Form.Input
              fluid
              icon="mail"
              iconPosition="left"
              value={userResetCredentials.email}
              onChange={(e, { value }) =>
                setUserResetCredentials({
                  ...userResetCredentials,
                  email: value,
                })
              }
              placeholder="Email Address"
            />
          )}
          <Form.Input
            fluid
            icon="lock"
            iconPosition="left"
            value={userResetCredentials.new_password}
            onChange={(e, { value }) => {
              setUserResetCredentials({
                ...userResetCredentials,
                new_password: value,
              });
            }}
            placeholder="New Password"
            type="password"
          />
          <Form.Input
            fluid
            icon="lock"
            iconPosition="left"
            value={userResetCredentials.new_password_again}
            onChange={(e, { value }) => {
              setUserResetCredentials({
                ...userResetCredentials,
                new_password_again: value,
              });
            }}
            placeholder="Retype Your New Password"
            type="password"
          />
          <Button color="teal" fluid size="large" disabled={formInvalid}>
            Reset
          </Button>
        </Segment>
      </Form>
    </div>
  );
};

export default PasswordResetForm;
