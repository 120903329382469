import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Card, Message, Button, Icon, Modal, Dropdown, Input } from 'semantic-ui-react';
import {
  ServiceModals,
  StatusViewState,
  TestCardsProps,
  TestMessage,
  ColorClass,
  SingleTest,
  TestData,
} from '../../../types';
import { formatDateTime } from '../../../utils/time';
import ResolutionForm from '../Forms/ResolutionForm';
import '../css/sonar.css';

const TestCards = ({ client, testData }: TestCardsProps) => {
  const [open, setOpen] = useState(false);
  const [servicesOpen, setServicesOpen] = useState<ServiceModals>({
    modal_id: null,
  });
  const [message, setMessageState] = useState<TestMessage>({
    text: 'No Tests Have been Initiated',
  });
  const [statusView, setStatusViewState] = useState<StatusViewState>({
    view: 'ALL',
    text: '',
  });

  // Assign color and color classNames to test_type keys
  const tsStrings = {
    ANALYTICS: {
      name: 'Analytics',
      color: '#2185d0',
      colorName: 'blue',
    },
    CAMPAIGN_MGMT: {
      name: 'Campaign Management',
      color: '#3DBEAF',
      colorName: 'teal',
    },
    CONVERSION_FLOW: {
      name: 'Conversion Flow',
      color: '#114f82',
      colorName: 'dark-blue',
    },
    HIPAA_COMPLIANCE: {
      name: 'HIPAA Compliance',
      color: '#1852AB',
      colorName: 'royal-blue',
    },
    USER_FLOW: {
      name: 'User Flow',
      color: '#79C7D4',
      colorName: 'light-blue',
    },
  };

  // Invokes a Single Test
  const runSingleTest = (e, data) => {
    const inputSting =
      '{"data": {"run_type": "SINGLE_TEST","client_id": "' +
      data.client_id +
      '","test_id": "' +
      data.test_id +
      '"}, "runtime": "' +
      new Date().toISOString() +
      '"}';
    const bodyData = JSON.stringify({
      input: inputSting,
      name: 'Manual-Test-Run-' + data.test_id + '-' + Date.now(),
      stateMachineArn: 'arn:aws:states:us-west-2:145992106078:stateMachine:SonarRunTestsMachine',
    });

    // Simple Post to Endpoint to Invoke Test
    fetch('https://w5l9eswd59.execute-api.us-west-2.amazonaws.com/v1/execute', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: bodyData,
    })
      .then((res) => res.json())
      .then((response) => {
        const msgResponse: TestMessage = {
          text: JSON.stringify(response),
        };
        setMessageState(msgResponse);
        setOpen(true);
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.log(error));
  };

  const renderTestModal = (client_test) => (
    <Modal
      onClose={() => setServicesOpen({ modal_id: null })}
      onOpen={() => setServicesOpen({ modal_id: client_test.test.test_id + '_modal' })}
      open={servicesOpen.modal_id === client_test.test.test_id + '_modal'}
      key={client_test.test.test_id + '_modal'}
    >
      <Modal.Header>Resolve {client_test.test.name}</Modal.Header>

      <Modal.Content>
        {client_test.test.test_status === 'PASSING' && (
          <Modal.Description content={<ResolutionForm test_id={client_test.test.test_id} />} />
        )}
        {client_test.test.test_status === 'FAILING' && (
          <Modal.Description
            content={
              <Message color="orange">
                This test is currently failing and cannot be resolved at this time. Please try again after your test is
                passing.
              </Message>
            }
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Ok"
          labelPosition="right"
          icon="checkmark"
          onClick={() => setServicesOpen({ modal_id: null })}
          primary
        />
      </Modal.Actions>
    </Modal>
  );

  const getCardColorClass = (test) => {
    const colorClass: ColorClass = {
      color: 'grey',
      icon: 'warning circle',
    };
    if (test.test_type === 'CAMPAIGN_MGMT' && test.test_status === 'FAILING') {
      colorClass.color = 'red';
      colorClass.icon = 'pause';
    } else if (test.test_type === 'CAMPAIGN_MGMT' && test.test_status === 'PASSING') {
      colorClass.color = 'green';
      colorClass.icon = 'play';
    } else if (test.test_status === 'FAILING') {
      colorClass.color = 'red';
      colorClass.icon = 'warning circle';
    } else if (test.test_status === 'PASSING' && test.resolved_state === false) {
      colorClass.color = 'yellow';
      colorClass.icon = 'check';
    } else if (test.test_status === 'PASSING' && test.resolved_state === true) {
      colorClass.color = 'green';
      colorClass.icon = 'check';
    }
    return colorClass;
  };

  const renderTestCard = (client, client_test) => (
    <Card key={client_test.test.test_id + '_card'} fluid color={getCardColorClass(client_test.test).color}>
      <Card.Content>
        <Grid container columns={16} verticalAlign="middle">
          <Grid.Row>
            <Grid.Column width={1}>
              <Icon
                className={getCardColorClass(client_test.test).icon}
                color={getCardColorClass(client_test.test).color}
              ></Icon>
            </Grid.Column>

            <Grid.Column width={2}>
              <Card.Meta>
                <span className="test-updated">
                  {' '}
                  {client_test.test.last_executed ? formatDateTime(client_test.test.last_executed) : 'No Test Data'}
                </span>
              </Card.Meta>
            </Grid.Column>

            <Grid.Column width={6}>
              <Card.Header>
                {/* eslint-disable-next-line react/prop-types */}
                <Link to={`/sonar/client/${client.client_id}/edit_test/${client_test.test.test_id}`}>
                  {client_test.test.name}
                </Link>
              </Card.Header>
              <p className="test-description">{client_test.test.description}</p>
            </Grid.Column>
            <Grid.Column textAlign="left" width={4}>
              <Button
                className={client_test.test.test_type ? tsStrings[client_test.test.test_type].colorName : 'grey'}
                style={{
                  borderRadius: '15px',
                  cursor: 'arrow',
                  fontWeight: '400',
                  minWidth: '150px',
                  padding: '.5em 1em',
                  textTransform: 'none',
                }}
              >
                {client_test.test.test_type ? tsStrings[client_test.test.test_type].name : 'Uncategorized'}
              </Button>
            </Grid.Column>
            <Grid.Column textAlign="left" width={3} className="icon-container" style={{ cursor: 'pointer' }}>
              {/* eslint-disable-next-line react/prop-types */}
              <Icon
                className="redo alternate button"
                color="green"
                // eslint-disable-next-line react/prop-types
                client_id={client.client_id}
                test_id={client_test.test.test_id}
                onClick={runSingleTest}
                style={{ cursor: 'pointer' }}
                title="Run Test"
              ></Icon>
              <Link to={`/sonar/client/${client.client_id}/edit_test/${client_test.test.test_id}`}>
                <Icon className="edit" color="grey" title="Edit Test"></Icon>
              </Link>

              {/* eslint-disable-next-line react/prop-types */}
              <Link to={`/sonar/client/${client.client_id}/view_test/${client_test.test.test_id}`}>
                <Icon className="eye" color="grey" title="View Test"></Icon>
              </Link>
              {client_test.test.resolved_state === false && (
                <Icon
                  className="file outline button"
                  onClick={() =>
                    setServicesOpen({
                      modal_id: client_test.test.test_id + '_modal',
                    })
                  }
                  color="grey"
                  title="Resolve Test"
                ></Icon>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {client_test.test.test_status ? renderTestModal(client_test) : ''}
      </Card.Content>
    </Card>
  );
  const statusOptions = [
    {
      key: 'ALL',
      text: 'All',
      value: 'ALL',
    },
    {
      key: 'FAILING',
      text: 'Failing',
      value: 'FAILING',
    },
    {
      key: 'PASSING',
      text: 'Passing',
      value: 'PASSING',
    },
    {
      key: 'UNRESOLVED',
      text: 'Unresolved',
      value: 'UNRESOLVED',
    },
    {
      key: 'NO_DATA',
      text: 'No Data',
      value: 'NO_DATA',
    },
  ];
  //Sorts tests in alphabetical order
  const testsSorted = testData
    ?.slice()
    .sort((a: SingleTest, b: SingleTest) => (a.test.name.toLowerCase() > b.test.name.toLowerCase() ? 1 : -1));

  const handleFilters = (testsSorted: TestData) => {
    let filteredTests = testsSorted;
    if (statusView.view !== 'ALL') {
      filteredTests = filteredTests.filter((test) => {
        switch (statusView.view) {
          case 'PASSING':
            return test.test.test_status === 'PASSING' && test.test.resolved_state === true;
          case 'UNRESOLVED':
            return test.test.test_status === 'PASSING' && test.test.resolved_state === false;
          case 'FAILING':
            return test.test.test_status === 'FAILING';
          case 'NO_DATA':
            return test.test.test_status === null;
        }
      });
    }
    if (statusView.text) {
      filteredTests = filteredTests.filter((test) => {
        const lowerCased = test.test.name.toLowerCase();
        return lowerCased.search(statusView.text.toLowerCase()) != -1;
      });
    }
    return filteredTests;
  };
  return (
    <div>
      <Grid padded>
        <Dropdown
          placeholder="Filter by Status"
          selection
          options={statusOptions}
          onChange={(e, { value }) => setStatusViewState({ ...statusView, view: value as string })}
        ></Dropdown>
        <Input
          placeholder="Search"
          onChange={(e, { value }) => setStatusViewState({ ...statusView, text: value as string })}
        ></Input>
      </Grid>
      <Card.Group>
        {handleFilters(testsSorted).map((clientTest: SingleTest) => {
          return renderTestCard(client, clientTest);
        })}
      </Card.Group>

      <Modal onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open={open}>
        <Modal.Header>Testing Initiated</Modal.Header>
        <Modal.Content>
          <Modal.Description content={message.text} />
        </Modal.Content>
        <Modal.Actions>
          <Button content="Ok" labelPosition="right" icon="checkmark" onClick={() => setOpen(false)} primary />
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default TestCards;
