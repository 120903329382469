import { useQuery, gql } from '@apollo/client';
import { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import SyntaxHighlighter from 'react-syntax-highlighter';
import solarized_dark from 'react-syntax-highlighter/dist/esm/styles/hljs/solarized-dark';
import {
  Grid,
  Card,
  Segment,
  Icon,
  Container,
  GridRow,
  Header,
  Menu,
  GridColumn,
  Button,
  Message,
  Tab,
  CardDescription,
} from 'semantic-ui-react';
import '../css/sonar.css';
import { SonarHeader } from '../../../components';
import { formatDateTime } from '../../../utils/time';
import { StyledMenu } from '../../Settings';

const GET_CLIENT_TEST = gql`
  query getClientTest($client_id: uuid!, $test_id: uuid!) {
    sonar_client_test(where: { client_id: { _eq: $client_id }, test_id: { _eq: $test_id } }) {
      client_id
      test {
        test_id
        name
        description
        enabled
        log_bucket
        log_path
        network_capture_config
        start_url
        test_status
        step_sequence
        resolved_state
        last_executed
        resolved_state
        steps {
          step_id
          name
          type
          action_type
          sequence
          config
          updated
        }
      }
    }
  }
`;

const GET_TEST_LOGS = gql`
  query getLogEntries($test_id: uuid!) {
    services_log(where: { test_id: { _eq: $test_id } }) {
      log_id
      resolved_status
      impact_notes
      resolution_notes
      time_resolved
      updated
      user {
        family_name
        given_name
        cognito_email
      }
    }
  }
`;

type ClientTest = {
  description: string;
  enabled: boolean;
  last_executed: string;
  log_bucket: string;
  log_path: string;
  name: string;
  network_capture_config: string;
  resolved_state: boolean;
  start_url: string;
  steps: [
    {
      step: {
        action_type: string;
        config: string;
        name: string;
        sequence: number;
        step_id: string;
        type: string;
        updated: string;
      };
    },
  ];
  test_id: string;
  test_status: string;
};

type logData = {
  body: string;
};

type ServicesLog = {
  impact_notes: string;
  log_id: string;
  resolution_notes: string;
  resolved_status: string;
  time_resolved: string;
  updated: string;
  user: {
    cognito_email: string;
    family_name: string;
    given_name: string;
  };
};

interface logFetchStatus {
  log_fetched: boolean;
}

type TestClientId = {
  client_id: string;
  test_id: string;
};

export const ViewTest = () => {
  const { client_id, test_id } = useParams<TestClientId>();
  const { loading, data } = useQuery(GET_CLIENT_TEST, {
    variables: { client_id, test_id },
  });

  const {
    loading: resLoading,
    error: resError,
    data: resData,
  } = useQuery(GET_TEST_LOGS, {
    variables: { test_id },
  });

  const [log, setLogState] = useState<logData>({
    body: 'Loading...',
  });

  const tabPanes = [
    // eslint-disable-next-line react/display-name
    {
      menuItem: 'Last Test Log',
      render: () => <Tab.Pane>{renderTestLog(test.log_path, log)}</Tab.Pane>,
    },
    {
      menuItem: 'Resolution Log',
      // eslint-disable-next-line react/display-name
      render: () => (
        <div>
          <Tab.Pane>
            <Grid container className="resolution_items" columns={16} style={{ paddingLeft: 10 }}>
              {resData.services_log?.length === 0 && (
                <Grid.Column width={16}>
                  <Message color="teal">
                    <span>There currently are no resolution logs for this issue.</span>
                  </Message>
                </Grid.Column>
              )}
              <Grid.Column width={2}>
                <Card.Header>
                  <strong>Resolution</strong>
                </Card.Header>
              </Grid.Column>
              <Grid.Column width={4}>
                <Card.Header>
                  <strong>Impact?</strong>
                </Card.Header>
              </Grid.Column>
              <Grid.Column width={4}>
                <Card.Header>
                  <strong>Resolution Notes</strong>
                </Card.Header>
              </Grid.Column>
              <Grid.Column width={3}>
                <Card.Header>
                  <strong>Date Resolved</strong>
                </Card.Header>
              </Grid.Column>
              <Grid.Column width={3}>
                <Card.Header style={{ minWidth: '150px' }}>
                  <strong>Resolved By</strong>
                </Card.Header>
              </Grid.Column>
            </Grid>
            {resData.services_log?.map((resolution_item: ServicesLog) => {
              return renderResolutionItem(resolution_item);
            })}
          </Tab.Pane>
        </div>
      ),
    },
  ];

  const [logFetched, setLogFetchedState] = useState<logFetchStatus>({
    log_fetched: false,
  });

  //Use Apollo's loading feature to prevent errors
  if (loading) {
    return null;
  }

  if (resLoading) {
    return <p>Loading...</p>;
  }
  if (resError) {
    return <p>Error :(</p>;
  }

  const test: ClientTest = data.sonar_client_test[0].test;

  if (test.log_path === null) {
    return (
      <div>
        <StyledMenu pointing secondary>
          <Menu.Item name="Back to Test Flow List">
            <Link to={`/sonar/client/${client_id}`}>
              <Icon name="angle left" />
              <span>Back to Test Flow List</span>
            </Link>
          </Menu.Item>
        </StyledMenu>
        <Segment placeholder>
          <Header icon>
            <Icon name="compass outline" />
            Please run the Test at least once to see Test Details.
          </Header>
        </Segment>
      </div>
    );
  }

  const renderResolutionItem = (logItem) => {
    return (
      <Card key={logItem.log_id + '_card'} fluid>
        <Card.Content>
          <Grid container columns={16}>
            <Grid.Column width={2}>
              <Button
                className={'teal'}
                style={{
                  borderRadius: '15px',
                  cursor: 'arrow',
                  fontWeight: '400',
                  minWidth: '100px',
                  padding: '.5em 1em',
                  textTransform: 'none',
                }}
              >
                {logItem.resolved_status}
              </Button>
            </Grid.Column>
            <Grid.Column width={4}>
              <CardDescription>
                <p>{logItem.impact_notes}</p>
              </CardDescription>
            </Grid.Column>
            <Grid.Column width={4}>
              <CardDescription>
                <p>{logItem.resolution_notes}</p>
              </CardDescription>
            </Grid.Column>
            <Grid.Column width={3}>
              <CardDescription>
                <p>{formatDateTime(logItem.time_resolved)}</p>
              </CardDescription>
            </Grid.Column>
            <Grid.Column width={3}>
              <CardDescription>
                <p>
                  {logItem.user?.given_name && logItem.user?.family_name
                    ? logItem.user?.given_name + ' ' + logItem.user?.family_name
                    : logItem.user?.cognito_email}
                </p>
              </CardDescription>
            </Grid.Column>
          </Grid>
        </Card.Content>
      </Card>
    );
  };

  const renderTestLog = (log_name, log) => {
    const logLabel = log_name.match(/lake\/sonar\/logs\/(.*$)/)[1];
    //Simple Post to Endpoint to Invoke Test
    if (!logFetched.log_fetched) {
      fetch(`https://v2q6ax6tb8.execute-api.us-west-2.amazonaws.com/v1/${logLabel}`, {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/text',
        }),
      })
        .then((response) => {
          setLogFetchedState({ log_fetched: true });
          response.text().then(function (text) {
            setLogState({ body: text });
            return text;
          });
        })
        // eslint-disable-next-line no-console
        .catch((error) => console.log(error));
    }
    return (
      <div>
        <strong>Viewing: {log_name}</strong>
        <SyntaxHighlighter language="haskell" style={solarized_dark}>
          {log.body}
        </SyntaxHighlighter>
      </div>
    );
  };

  return (
    <div>
      <SonarHeader
        headerSettings={{
          mode: 'small',
          subTitle: test.description,
          title: test.name,
        }}
      />
      <Grid>
        <GridColumn width={16}>
          <GridRow>
            <Container>
              <h1>{test.name}</h1>
              {test.test_status === 'PASSING' && (
                <Message color="green" style={{ marginTop: '15px' }}>
                  {test.test_status} <span>| Last Executed: {formatDateTime(test.last_executed)}</span>
                </Message>
              )}
              {test.test_status === 'FAILING' && (
                <Message color="red" style={{ marginTop: '15px' }}>
                  {test.test_status} <span>| Last Executed: {formatDateTime(test.last_executed)}</span>
                </Message>
              )}
              {!test.resolved_state && <Message color="red">Test is in an unresolved state.</Message>}
              <Tab test={test} log={log} panes={tabPanes} />
            </Container>
          </GridRow>
        </GridColumn>
      </Grid>
    </div>
  );
};
