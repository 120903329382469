import { useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet';
import { useParams, Link, Redirect } from 'react-router-dom';
import { Grid, Button } from 'semantic-ui-react';
import { SonarHeader } from '../../../components';
import TestCards from '../Cards/TestCards';
import PieCharts from '../Charts/PieCharts';
import { GET_ACTIVE_USER, GET_CLIENT_BY_UUID, GET_ACTIVE_TESTS } from '../gql/queries.gql';
import '../css/sonar.css';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';

type ClientId = {
  client_id: string;
};

export const ViewClient = () => {
  const { client_id } = useParams<ClientId>();

  // Assign color and color classNames to test_type keys
  const tsStrings = {
    ANALYTICS: {
      name: 'Analytics',
      color: '#2185d0',
      colorName: 'blue',
    },
    CAMPAIGN_MGMT: {
      name: 'Campaign Management',
      color: '#3DBEAF',
      colorName: 'teal',
    },
    CONVERSION_FLOW: {
      name: 'Conversion Flow',
      color: '#114f82',
      colorName: 'dark-blue',
    },
    HIPAA_COMPLIANCE: {
      name: 'HIPAA Compliance',
      color: '#1852AB',
      colorName: 'royal-blue',
    },
    null: {
      name: 'Uncategorized',
      color: '#808080',
      colorName: 'gray',
    },
    USER_FLOW: {
      name: 'User Flow',
      color: '#79C7D4',
      colorName: 'light-blue',
    },
  };

  const { loading, data } = useQuery(GET_CLIENT_BY_UUID, {
    variables: { client_id },
    fetchPolicy: 'cache-first',
  });

  const { loading: l, data: current } = useQuery(GET_ACTIVE_USER, {
    fetchPolicy: 'no-cache',
  });

  const { loading: loadingTests, data: testData } = useQuery(GET_ACTIVE_TESTS, {
    variables: { client_id },
    fetchPolicy: 'cache-first',
  });

  //Use Apollo's loading feature to prevent errors
  if (loading || l || loadingTests) {
    return null;
  }

  const renderClient = (client) => {
    const redirectString = `/sonar/client/${current.client_user[0].active_client_id}`;
    return (
      <div>
        {current.client_user[0].active_client_id !== client_id && <Redirect to={redirectString} />}
        <Helmet>
          <title>Sonar</title>
        </Helmet>
        <SonarHeader
          headerSettings={{
            mode: 'full',
            subTitle: 'Tracking critical data attribution and user flows.',
            title: 'SONAR',
          }}
        />

        <PieCharts styleData={tsStrings} />

        <Grid container id="testGrid" stackable relaxed="very" style={{ marginTop: '50px' }}>
          <Grid.Row>
            <Grid.Column width={16} style={{ paddingLeft: '1.5rem' }}>
              {testData && <TestCards client={client} testData={testData.client_client_by_pk.client_client_test} />}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column
              width={16}
              style={{
                textAlign: 'center',
                paddingTop: '3rem',
                paddingBottom: '5rem',
              }}
            >
              <Link to={`/sonar/client/${client.client_id}/library/`}>
                <Button primary client_id={client.client_id} style={{ minWidth: '300px', fontWeight: 500 }}>
                  View All Tests
                </Button>
              </Link>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  };

  return <div>{renderClient(data.client_client_by_pk)}</div>;
};
