import { Grid, Statistic } from 'semantic-ui-react';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';

const ModelStats: React.FC<{
  csv: string;
  // eslint-disable-next-line react/function-component-definition
}> = ({ csv }) => {
  // Calculate journey metrics
  let beforeConv = 0;
  let interactions = 0;

  const rows = csv.split('\n').length;

  for (const row of csv.split('\n').slice(1)) {
    beforeConv += parseInt(row.split(',')[3]);
    interactions += row.split(',')[0].split('>').length * parseInt(row.split(',')[3]);
  }

  const beforeRows = rows;
  const avgPathLength = (interactions / beforeConv).toFixed(2);
  const totalInteractions = interactions;

  return (
    <>
      <Grid.Row columns={1}>
        <Grid.Column>
          <Statistic.Group widths={4}>
            <Statistic>
              <Statistic.Value>{beforeConv.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Statistic.Value>
              <Statistic.Label>Conversions</Statistic.Label>
            </Statistic>
            <Statistic>
              <Statistic.Value>{beforeRows.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Statistic.Value>
              <Statistic.Label>Unique Journeys</Statistic.Label>
            </Statistic>
            <Statistic>
              <Statistic.Value>{avgPathLength.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Statistic.Value>
              <Statistic.Label>Avg. Path Length</Statistic.Label>
            </Statistic>
            <Statistic>
              <Statistic.Value>{totalInteractions.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Statistic.Value>
              <Statistic.Label>Interactions</Statistic.Label>
            </Statistic>
          </Statistic.Group>
        </Grid.Column>
      </Grid.Row>
    </>
  );
};

export default ModelStats;
